import { load } from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  HOME: {
    path: '/',
    exact: true,
    component: load('Home')
  },

  SIGNUP_PAYMENT_VERIFICATION: {
    path: '/thanks',
    exact: true,
    component: load('SignupPaymentConfirmation')
  },

  DASHBOARD: {
    path: '/dashboard',
    exact: false,
    component: load('Dashboard')
  },

  USERDASHBOARD: {
    path: '/dashboard',
    exact: false,
    component: load('UserDashboard')
  },

  EXPERTDASHBOARD: {
    path: '/dashboard',
    exact: false,
    component: load('ExpertDashboard')
  },

  DASHBOARDINDEX: {
    path: '/dashboard',
    exact: true,
    component: load('DashboardIndex')
  },

  EXPERTREQUESTSLIST: {
    path: '/dashboard',
    exact: true,
    component: load('ExpertRequestsList')
  },

  SESSIONSLIST: {
    path: '/dashboard/list',
    exact: true,
    component: load('SessionsList')
  },

  AFFILIATES: {
    path: '/dashboard/affiliates',
    exact: true,
    component: load('UserAffliates')
  },

  USERACCOUNT: {
    path: '/dashboard/accounts',
    exact: true,
    component: load('UserAccount')
  },

  NEWSESSION: {
    path: '/dashboard/new-session',
    exact: true,
    component: load('NewSession')
  },

  RECORDCLIENTSESSION: {
    path: '/dashboard/recoder/client',
    exact: true,
    component: load('RecordClientSession')
  },

  RECORDCOACHSESSION: {
    path: '/dashboard/recoder/coach',
    exact: true,
    component: load('RecordCoachSession')
  },

  RECORDEXPERTVIDEO: {
    path: '/dashboard/recoder/expert/:id',
    exact: true,
    component: load('RecordExpertVideo')
  },

  RECORDINGTODOS: {
    path: '/dashboard/toDos',
    exact: true,
    component: load('RecordingTodos')
  },

  SESSIONCOMPLETE: {
    path: '/dashboard/session-complete/:id',
    exact: true,
    component: load('SessionComplete')
  },

  SWITCHMODE: {
    path: '/dashboard/switchMode',
    exact: true,
    component: load('RecordingSwitchMode')
  },

  EXTERNALVIDEO: {
    path: '/dashboard/externalVideo/:id',
    exact: true,
    component: load('ExternalVideo')
  },

  ACTIVATIONLINK: {
    path: '/user/activate-account/:id',
    exact: true,
    component: load('ThankYou')
  },

  WATCHVIDEO: {
    path: '/dashboard/watchVideo',
    exact: true,
    component: load('WatchVideo')
  },

  COACHINSTRUCTIONS: {
    path: '/dashboard/coachInstruction',
    exact: true,
    component: load('CoachInsturctions')
  },

  REPLAYSESSION: {
    path: '/dashboard/replaySession/:id',
    exact: true,
    component: load('ReplaySession')
  },

  LOGIN: {
    path: '/login',
    exact: true,
    component: load('Login')
  },

  LOGOUT: {
    path: '/logout',
    exact: true,
    component: load('Logout')
  },

  STATICPAGE: {
    path: '/:pageSlug',
    exact: true,
    component: load('StaticPage')
  },

  RESETPASSWORD: {
    path: '/password/reset/:token',
    exact: true,
    component: load('ResetPassword')
  },

  BUYSUBSCRIPTION: {
    path: '/dashboard/subscription',
    exact: true,
    component: load('BuySubscription')
  },

  SUCCESSPAYMENT: {
    path: '/dashboard/success',
    exact: true,
    component: load('SuccessPayment')
  },

  NOTFOUND: {
    path: '/page-not-found',
    exact: true,
    component: load('PageNotFound')
  },

  FAQ_PAGE: {
    path: '/common/faq',
    exact: true,
    component: load('FaqsPage'),
  },
  LASERSUCCESSPAYMENT: {
    path: '/dashboard/laser-success',
    exact: true,
    component: load('LaserSuccess')
  },
  
};